import React from "react";
import Typewriter from "typewriter-effect";

function Type() {
  return (
    <Typewriter
      options={{
        strings: [
        "你好",
        "Hola",
        "Hello",
        "नमस्ते",
        "مرحبا",
        "হ্যালো",
        "Olá",
        "Здравствуйте",
        "こんにちは",
        "Hallo",
        ],
        autoStart: true,
        loop: true,
        deleteSpeed: 50,
      }}
    />
  );
}

export default Type;
